<template>
  <div class="çol-12 mb-3">
    <basic-card>
      <template #header>
        <h5>Informatie</h5>
      </template>

      <slide-data-form />
    </basic-card>
  </div>

  <div class="çol-12">
    <basic-card>
      <template #header>
        <h5>Preview</h5>
      </template>

      <slide-preview-list />
    </basic-card>
  </div>
</template>

<script>

import BasicCard from '@/components/UI/BasicCard.vue';

import SlideDataForm from '@/components/WelcomeSlide/SlideDataForm/SlideDataForm.vue';
import SlidePreviewList from '@/components/WelcomeSlide/SlidePreviewList.vue'

import WelcomeSlideStore from '@/store/pages/WelcomeSlideStore/welcome-slide-store.js';

export default {
  components: {
    BasicCard,
    SlideDataForm,
    SlidePreviewList,
  },

  created() {
    WelcomeSlideStore.dispatch('fetchRemoteSlides');
  },
}
</script>