<script>
export default {
  props: {
    slide: { type: Object, required: true, },
  },

  computed: {
    hasText() {
      return this.slide.description !== "";
    },
    textStyle() {
      return {
        color: this.slide.textColor ?? "#000000"
      }
    }
  }
}
</script>

<template>
  <p
    v-if="hasText"
    class="text-center mb-2"
    :style="textStyle"
  >
    {{ slide.description }}
  </p>
  <p 
    v-else
    :style="textStyle"
  >
    Tekst
  </p>
</template>

<style>
p {
  font-size: 0.5em;
  margin: 0;
  margin-bottom: 1em;
  font-family: 'Figtree';
  font-weight: 500;
}
</style>