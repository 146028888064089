<script>
import WelcomeSlideStore from '@/store/pages/WelcomeSlideStore/welcome-slide-store.js';

import MaterialInput from '@/components/MaterialInput.vue';
import MaterialTextarea from '@/components/MaterialTextarea.vue'

export default {
  components: {
    MaterialInput,
    MaterialTextarea,
  },

  computed: {
    title: {
      get() { return WelcomeSlideStore.getters.currentSlide?.title ?? ""; },
      set(value) {
        WelcomeSlideStore.dispatch('updateCurrentSlideTitle', value);
      }
    },

    description: {
      get() { return WelcomeSlideStore.getters.currentSlide?.description ?? ""; },
      set(value) {
        WelcomeSlideStore.dispatch('updateCurrentSlideDescription', value);
      }
    },

    buttonText: {
      get() { return WelcomeSlideStore.getters.currentSlide?.buttonText ?? ""; },
      set(value) {
        WelcomeSlideStore.dispatch('updateCurrentSlideButtonText', value);
      }
    },
  }
}
</script>

<template>
  <div>
    <h6>Tekst</h6>
    <material-input
      id="slide-form-title-input"
      v-model="title"
      label="Titel"
      class="mb-2"
    />

    <material-textarea
      id="slide-form-description-input"
      v-model="description"
      label="Beschrijving"
      class="mb-2"
    />

    <material-input
      id="slide-form-button-label-input"
      v-model="buttonText"
      label="Label knop"
      class="mb-2"
    />
  </div>
</template>