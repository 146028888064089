<script>
import WelcomeSlideStore from '@/store/pages/WelcomeSlideStore/welcome-slide-store.js';

import SingleImagePickerButton from '@/components/UI/SingleImagePickerButton.vue';

export default {
  components: {
    SingleImagePickerButton,
  },

  computed: {
    backgroundData: {
      get() {
        const hasUrl = (
          WelcomeSlideStore.getters.currentSlide?.backgroundUrl != null &&
          WelcomeSlideStore.getters.currentSlide?.backgroundUrl !== ""
        );

        const hasBase64 = (
          WelcomeSlideStore.getters.currentSlide?.backgroundBase64 != null &&  
          WelcomeSlideStore.getters.currentSlide?.backgroundBase64 !== ""
        );

        if (hasBase64) return WelcomeSlideStore.getters.currentSlide?.backgroundBase64;
        if (hasUrl) return WelcomeSlideStore.getters.currentSlide?.backgroundUrl;

        return null;
      },
      set(value) {
        WelcomeSlideStore.dispatch('updateCurrentSlideBackgroundBase64', value)
      }
    },
    iconData: {
      get() {
        const hasUrl = (
          WelcomeSlideStore.getters.currentSlide?.iconUrl != null &&
          WelcomeSlideStore.getters.currentSlide?.iconUrl !== ""
        );

        const hasBase64 = (
          WelcomeSlideStore.getters.currentSlide?.iconBase64 != null &&  
          WelcomeSlideStore.getters.currentSlide?.iconBase64 !== ""
        );

        if (hasBase64) return WelcomeSlideStore.getters.currentSlide?.iconBase64;
        if (hasUrl) return WelcomeSlideStore.getters.currentSlide?.iconUrl;

        return null;
      },
      set(value) {
        WelcomeSlideStore.dispatch('updateCurrentSlideIconBase64', value)
      }
    }
  },
}
</script>

<template>
  <div>
    <h6>Afbeeldingen</h6>
    <single-image-picker-button
      v-model="backgroundData"
      :max-image-height="1080"
      :max-image-width="1920"
      label="Achtergrond"
      class="mb-2"
    />

    <single-image-picker-button
      v-model="iconData"
      :max-image-height="600"
      :max-image-width="600"
      label="Icoon"
      class="mb-2"
    />
  </div>
</template>