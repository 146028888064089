<template>
  <div
    id="slide-data-form"
  >
    <div class="row justify-content-end">
      <div class="col-sm-8 col-xs-12">
        <slide-data-form-text />
        <slide-data-form-colors />
        <slide-data-form-images />
      </div>
      <div class="col-sm-4 col-xs-12">
        <slide-data-form-current-slide-preview />
      </div>
    </div>

    <material-button
      color="primary"
      @click.prevent="submit"
    >
      Opslaan
    </material-button>
  </div>

  <div
    v-if="!hasActiveSlide"
    id="slide-data-form-overlay"
  >
    <h2>Selecteer een item om te bewerken</h2>
    <span class="material-icons-round">arrow_downward</span>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import WelcomeSlideStore from '@/store/pages/WelcomeSlideStore/welcome-slide-store.js';

import MaterialButton from '@/components/MaterialButton.vue';
import SlideDataFormColors from '@/components/WelcomeSlide/SlideDataForm/SlideDataFormColors.vue';
import SlideDataFormCurrentSlidePreview from '@/components/WelcomeSlide/SlideDataForm/SlideDataFormCurrentSlidePreview.vue';
import SlideDataFormText from '@/components/WelcomeSlide/SlideDataForm/SlideDataFormText.vue';
import SlideDataFormImages from './SlideDataFormImages.vue';

export default {
  components: {
    MaterialButton,
    SlideDataFormColors,
    SlideDataFormCurrentSlidePreview,
    SlideDataFormText,
    SlideDataFormImages,
  },

  data() {
    return {
      isSubmitting: false,
    };
  },

  computed: {
    hasActiveSlide() {
      return WelcomeSlideStore.getters.currentSlide != null;
    }
  },

  methods: {
    ...mapActions(
      'notification', 
      {
        addNotification: 'add',
      }
    ),

    showNotification(message) {
      this.addNotification({
        description: message ?? "De data is opgeslagen.",
        icon: {
          component: 'smartphone',
        },
      });
    },

    async submit() {
      if (this.isSubmitting) return;

      this.isSubmitting = true;

      try {
        const newSlidesResult = await WelcomeSlideStore.dispatch('saveNewSlides');
        if (newSlidesResult != null) {
          this.showNotification(newSlidesResult);
          return;
        }

        const updateSlidesResult = await WelcomeSlideStore.dispatch('saveUpdatedSlides');
        if (updateSlidesResult != null) {
          this.showNotification(updateSlidesResult);
          return;
        }

        const removeSlidesResult = await WelcomeSlideStore.dispatch('saveRemovedSlides');
        if (removeSlidesResult != null) {
          this.showNotification(removeSlidesResult);
          return;
        }
      }
      catch (e) {
        this.isSubmitting = false;
        this.showNotification("Er is een fout opgeslagen tijdens het opslaan");
        return;
      }

      this.isSubmitting = false;
      this.showNotification();
    }
  }
}
</script>

<style lang="scss" scoped>
#slide-data-form {
  position: relative;
}

#slide-data-form-overlay {
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 0%;
  left: 0%;

  height: 100%;
  width: 100%;
  border-radius: 10px;

  background-color: #00000088;

  h2, .material-icons-round {
    color: #ffffff;
  }

  .material-icons-round {
    font-size: 2rem;

    animation: up-and-down;
    animation-duration: 0.7s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-direction: alternate;
    animation-iteration-count: infinite;
  }

  @keyframes up-and-down {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(15px)
  }
}
}
</style>