<script>
export default {
  props: {
    label: { type: String, required: true, },
    modelValue: { type: String, required: true},
  },

  emits: [
    'update:modelValue',
  ],

  data() {
    return {
      key: crypto.randomUUID(),
    };
  },

  computed: {
    color: {
      get() { return this.modelValue; },
      set(value) { this.$emit('update:modelValue', value); }
    },

    buttonIndicatorStyle() {
      return {
        backgroundColor: this.color,
      }
    }
  }
}
</script>

<template>
  <div class="color-picker-container">
    <label 
      :for="`input-${key}`"
      class="d-flex align-items-center justify-content-between"
      :class="color != null ? 'filled' : ''"
    >
      <span>{{ label }}</span>
      <div 
        class="color-indicator"
        :style="buttonIndicatorStyle"
      />

    </label>
    <input
      :id="`input-${key}`"
      v-model="color"
      type="color"
    />
  </div>
</template>

<style lang="scss" scoped>
label {
  margin: 0;
  border: 1px solid #d2d6da;
  border-radius: 0.375rem;
  padding: 10px;
  font-weight: 500;

  &:hover { 
    background: #00000004;
  }
  
  &:hover, 
  &.filled {
    font-weight: 700;
  }
}

input { 
  display: none;
}

.color-indicator {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  outline: 3px solid #0004;
  outline-offset: -3px;
}
</style>