<script>
import WelcomeSlideStore from '@/store/pages/WelcomeSlideStore/welcome-slide-store.js';

import { createYesNoSweetAlert } from '@/helpers/sweetalert';

export default {
  props: {
    selected: { type: Boolean, required: true },
    slide: { type: Object, required: true },
  },

  computed: {
    number() { 
      return WelcomeSlideStore.getters.slides.indexOf(this.slide) + 1
    }
  },

  methods: {
    moveBack() {
      if (!this.selected) return;

      WelcomeSlideStore.dispatch('moveSlideBack');
    },
    moveForward() {
      if (!this.selected) return;

      WelcomeSlideStore.dispatch('moveSlideForward');
    },
    remove() {
      if (!this.selected) return;

      createYesNoSweetAlert(
        `Wilt u "${this.slide.title}" verwijderen?`,
        () => WelcomeSlideStore.dispatch('removeCurrentSlide'),
      );
    },
  }
}
</script>

<template>
  <div
    class="slide-preview-header d-flex justify-content-between align-items-center px-2"
    :class="selected ? 'active' : ''"
    >
    {{ number }}
    <div
      class="slide-preview-header-buttons d-flex"
    >
      <div>
        <span
          class="material-icons-round slide-button"
          @click.prevent="moveBack"
        >
          chevron_left
        </span>
      </div>
      <div>
        <span
          class="material-icons-round slide-button"
          @click.prevent="moveForward"
        >
          chevron_right
        </span>
      </div>
      <div>
        <span
          class="material-icons-round slide-button"
          @click.prevent="remove"
        >
          delete
        </span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.slide-button {
  background: #e91e63;
  font-size: 5px;
  color: transparent;
  border: 1px solid transparent;
  border-radius: 50%;
  padding: 2px;
  margin-left: 5px;
  cursor: pointer;

  transition: all 50ms linear;

}

.slide-preview-header.active {
  font-weight: 500;
}

.slide-preview-header.active .slide-button {
  font-size: 1em;
  color: #ffffff;

  &:hover {
    color: #e91e63;
    border-color: #e91e63;
    background: transparent;
  }
} 
</style>