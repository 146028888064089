<template>
  <img
    v-if="hasUrl"
    class="slide-preview-image-content image"
    :src="iconUrl"
    />
  <div 
    v-else
    class="slide-preview-image-content-placeholder"
  />
</template>

<script>
export default {
  props: {
    slide: { type: Object, required: true },
  },

  computed: {
    hasUrl() {
      return (
        this.slide.iconBase64 != null ||
        this.slide.iconUrl !== ""
      );
    },
    iconUrl() {
      return this.slide.iconBase64 ?? this.slide.iconUrl;
    }
  }
}
</script>

<style>
.slide-preview-image-content {
  width: 100%;
  transform: scale(0.4);
}

.slide-preview-image-content-placeholder {
  height: 40%;
  width: 100%;
  
  border: 1px dashed #82b9f5;
  background-color: transparent;
}
</style>