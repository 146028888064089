<script>
export default {
  props: {
    slide: { type: Object, required: true },
  },

  computed: {
    hasTitle() {
      return this.slide.title !== "";
    },
    textStyle() {
      return {
        color: this.slide.textColor ?? "#000000"
      }
    }
  }
}
</script>

<template>
  <h5
    v-if="hasTitle"
    :style="textStyle"
  >
    {{ slide.title }}
  </h5>
  <h5
    v-else
    :style="textStyle"
  >
    Titel
  </h5>
</template>

<style scoped>
h5 {
  font-size: 0.8em;
  margin-top: 0;
  margin-bottom: 0.5em;
  text-align: center;
  font-family: 'Figtree';
  font-weight: 700;
}
</style>