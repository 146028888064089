class WelcomeSlideDto {
	constructor(data) {
		this.id = data.id;
		this.title = data.title;
		this.description = data.description;
		this.sortOrder = data.sortOrder;
		this.organizationId = data.organizationId;
		this.buttonColorCode = data.buttonColorCode;
		this.buttonText = data.buttonText;
		this.buttonTextColorCode = data.buttonTextColorCode;
		this.textColorCode = data.textColorCode;
		this.visualIconImage = data.visualIconImage;
		this.visualBackgroundImage = data.visualBackgroundImage;
		this.organizationId = data.organizationId;
	}
}

export default WelcomeSlideDto;