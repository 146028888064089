<template>
  <div 
    class="slide-preview"
  >
    <slide-preview-header
      :selected="selected"
      :slide="slide"
    />
    <div
      class="slide-preview-container"
      :style="slideStyle"
    >
      <div
        class="slide-image-container"
      >
        <slide-preview-image
          :slide="slide"
        />
      </div>

      <div
        class="slide-content-container d-flex flex-column justify-content-center align-items-center"
      >
        <slide-preview-title
          :slide="slide"
        />

        <slide-preview-text
          :slide="slide"
        />

        <slide-preview-button
          :slide="slide"
        />
      </div>
    </div>
  </div>
</template>

<script>
import WelcomeSlideStore from '@/store/pages/WelcomeSlideStore/welcome-slide-store.js';

import SlidePreviewButton from './SlidePreviewButton.vue';
import SlidePreviewHeader from './SlidePreviewHeader.vue';
import SlidePreviewImage from './SlidePreviewImage.vue';
import SlidePreviewText from './SlidePreviewText.vue';
import SlidePreviewTitle from './SlidePreviewTitle.vue';

export default {
  components: {
    SlidePreviewButton,
    SlidePreviewHeader,
    SlidePreviewImage,
    SlidePreviewText,
    SlidePreviewTitle,
  },

  props: {
    selected: { type: Boolean, default: false },
    slide: { type: Object, required: true },
  },
  
  computed: {
    slides() {
      return WelcomeSlideStore.state.slides;
    },

    hasBackgroundImage() {
      return (
        this.slide.backgroundBase64 != null ||
        this.slide.backgroundUrl !== ""
      );
    },

    slideBackgroundUrl() {
      return this.slide.backgroundBase64 ??
        this.slide.backgroundUrl;
    },

    slideStyle() {
      return {
        'border-style': this.selected ? 'solid' : 'dashed',
        'background-image': this.hasBackgroundImage
          ? `url('${this.slideBackgroundUrl}')`
          : null
      };
    },
  }
}
</script>

<style lang="scss" scoped>
$preview-width: 200px;
$preview-height: calc($preview-width * 2.032);

.slide-preview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-block: 5px;
  margin-inline: 10px;

  font-size: 18px;

  height: $preview-height;
  width: $preview-width;
}

.slide-preview-number {
  margin-block: 0;
  margin-bottom: 5px;
}

.slide-preview-container {
  border: 1px dashed #82b9f5;
  border-radius: 20px;
  overflow: hidden;
  padding: 10%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  background-size: cover;
  background-position: center;

  width: $preview-width;
  height: 100%;
}

.slide-image-container {
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
}
</style>