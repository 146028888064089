class UpdateWelcomeSlideDto {
  constructor(data) {
    this.title = data.title;
    this.description = data.description;
    this.sortOrder = data.sortOrder;
    this.buttonColorCode = data.buttonColor
    this.buttonText = data.buttonText;
    this.buttonTextColorCode = data.buttonTextColor;
    this.textColorCode = data.textColor;
    this.id = data.id;
    this.organizationId = data.organizationId;
    this.visualIconImage = data.iconUrl;
    this.visualIconImageData = data.iconBase64;
    this.visualBackgroundImage = data.backgroundUrl;
    this.visualBackgroundImageData = data.backgroundBase64;
  }
}

export default UpdateWelcomeSlideDto;