import { createStore } from 'vuex'

import { 
  getSlides, 
  saveNewSlide, 
  updateSlide,
  removeSlide

} from '@/api/providers/welcome-slides';

import WelcomeSlideDto from '@/api/dto/welcome-slide-dto';
import WelcomeSlideModel from '@/models/welcome-slide';
import NewWelcomeSlideDto from '@/api/dto/new-welcome-slide-dto';
import UpdateWelcomeSlideDto from '@/api/dto/update-welcome-slide-dto';

const store = createStore({
  state () {
    return {
      slides: [],
      removedSlides: [],
      currentSlideIndex: 0
    }
  },

  getters: {
    slides(state) {
      return state.slides;
    },

    removedSlides(state) {
      return state.removedSlides;
    },

    currentSlide(state) {
      if (state.currentSlideIndex > state.slides.length - 1) return null;
      if (state.currentSlideIndex < 0) return null;

      return state.slides[state.currentSlideIndex];
    },

    currentSlideIndex(state) {
      return state.currentSlideIndex;
    },

    hasNoActiveSlide(state) {
      if (state.currentSlideIndex > state.slides.length - 1) return true;
      if (state.currentSlideIndex < 0) return true;

      return false;
    }
  },

  mutations: {
    initializeSlides(state, payload) {
      state.slides = payload;
    },

    addEmptySlide(state) {
      state.slides.push(WelcomeSlideModel.empty(state.slides.length));
      state.currentSlideIndex = state.slides.length - 1;
    },

    selectSlide(state, payload) {
      let index = state.slides.findIndex(s => s.key === payload);
      if (index < 0) return;
      state.currentSlideIndex = index;
    },

    updateCurrentSlideTitle(state, payload) {
      state.slides[state.currentSlideIndex].title = payload;
    },

    updateCurrentSlideDescription(state, payload) {
      state.slides[state.currentSlideIndex].description = payload;
    },

    updateCurrentSlideButtonText(state, payload) {
      state.slides[state.currentSlideIndex].buttonText = payload;
    },

    updateCurrentSlideTextColor(state, payload) {
      state.slides[state.currentSlideIndex].textColor = payload;
    },

    updateCurrentSlideButtonColor(state, payload) {
      state.slides[state.currentSlideIndex].buttonColor = payload;
    },

    updateCurrentSlideButtonTextColor(state, payload) {
      state.slides[state.currentSlideIndex].buttonTextColor = payload;
    },

    updateCurrentSlideBackgroundBase64(state, payload) {
      state.slides[state.currentSlideIndex].backgroundBase64 = payload;
    },

    updateCurrentSlideBackgroundUrl(state, payload) {
      state.slides[state.currentSlideIndex].backgroundUrl = payload;
    },

    updateCurrentSlideIconBase64(state, payload) {
      state.slides[state.currentSlideIndex].iconBase64 = payload;
    },

    updateCurrentSlideIconUrl(state, payload) {
      state.slides[state.currentSlideIndex].iconUrl = payload;
    },

    moveSlideBack(state) {
      if (state.currentSlideIndex < 1) return;

      const currentSlide = state.slides[state.currentSlideIndex];
      const previousSlide = state.slides[state.currentSlideIndex - 1];

      if (currentSlide == null || previousSlide == null) return;

      state.slides[state.currentSlideIndex - 1] = currentSlide;
      state.slides[state.currentSlideIndex] = previousSlide;

      state.currentSlideIndex -= 1;
    },

    moveSlideForward(state) {
      if (state.currentSlideIndex > state.slides.length - 2) return;

      const currentSlide = state.slides[state.currentSlideIndex];
      const nextSlide = state.slides[state.currentSlideIndex + 1];

      if (currentSlide == null || nextSlide == null) return;

      state.slides[state.currentSlideIndex + 1] = currentSlide;
      state.slides[state.currentSlideIndex] = nextSlide;

      state.currentSlideIndex += 1;
    },

    removeCurrentSlide(state) {
      if (state.currentSlideIndex < 0) return;
      if (state.currentSlideIndex > state.slides.length - 1) return;

      const isLastSlide = state.currentSlideIndex === state.slides.length - 1;

      state.removedSlides.push(
        // Use JSON as a substiture for deep cloning since structuredClone is not accessible.
        JSON.parse(JSON.stringify(state.slides[state.currentSlideIndex]))
      );
  
      state.slides.splice(state.currentSlideIndex, 1);

      if (isLastSlide) {
        state.currentSlideIndex = state.slides.length - 1;
      }
    },

    updateSortOrder(state) {
      for (const slide of state.slides.filter(s => !s.isDeleted)) {
        slide.sortOrder = state.slides.indexOf(slide)
      }
    }
  },

  actions: {
    addEmptySlide({commit}) {
      commit('addEmptySlide');
    },
    
    selectSlide({commit}, payload) {
      commit('selectSlide', payload);
    },

    updateCurrentSlideTitle({commit, getters}, payload) {
      if (getters.hasNoActiveSlide) return;

      commit('updateCurrentSlideTitle', payload);
    },

    updateCurrentSlideDescription({commit, getters}, payload) {
      if (getters.hasNoActiveSlide) return;

      commit('updateCurrentSlideDescription', payload);
    },

    updateCurrentSlideButtonText({commit, getters}, payload) {
      if (getters.hasNoActiveSlide) return;

      commit('updateCurrentSlideButtonText', payload);
    },

    updateCurrentSlideTextColor({commit, getters}, payload) {
      if (getters.hasNoActiveSlide) return;

      commit('updateCurrentSlideTextColor', payload);
    },

    updateCurrentSlideButtonColor({commit, getters}, payload) {
      if (getters.hasNoActiveSlide) return;

      commit('updateCurrentSlideButtonColor', payload);
    },

    updateCurrentSlideButtonTextColor({commit, getters}, payload) {
      if (getters.hasNoActiveSlide) return;

      commit('updateCurrentSlideButtonTextColor', payload);
    },

    updateCurrentSlideBackgroundBase64({commit, getters}, payload) {
      if (getters.hasNoActiveSlide) return;

      if (payload == null && getters.currentSlide.backgroundUrl != null) {
        commit('updateCurrentSlideBackgroundUrl', payload);
      }
      commit('updateCurrentSlideBackgroundBase64', payload);
    },

    updateCurrentSlideIconBase64({commit, getters}, payload) {
      if (getters.hasNoActiveSlide) return;
      
      if (payload == null && getters.currentSlide.iconUrl != null) {
        commit('updateCurrentSlideIconUrl', payload);
      }
      commit('updateCurrentSlideIconBase64', payload);
    },

    moveSlideBack({commit, getters}) {
      if (getters.hasNoActiveSlide) return;

      commit('moveSlideBack');
      commit('updateSortOrder');
    },

    moveSlideForward({commit, getters}) {
      if (getters.hasNoActiveSlide) return;

      commit('moveSlideForward');
      commit('updateSortOrder');
    },

    removeCurrentSlide({commit, getters,}) {
      if (getters.hasNoActiveSlide) return;

      commit('removeCurrentSlide');
      commit('updateSortOrder');
    },

    async fetchRemoteSlides({commit}) {
      const remoteSlidesData = await getSlides();

      const slideDtos = remoteSlidesData.map(s => new WelcomeSlideDto(s));
      
      const slides = slideDtos.map(s => WelcomeSlideModel.fromDto(s));

      commit('initializeSlides', slides);
    },

    async saveNewSlides({getters}) {
      if (getters.slides.length < 1) {
        return "Er moet minstens 1 item aangemaakt worden";
      }

      for (let slide of getters.slides.filter(s => s.isNew)) {
        const newSlideDto = new NewWelcomeSlideDto(slide);

        try {
          const result = await saveNewSlide(newSlideDto);
          const dto = WelcomeSlideModel.fromDto(result);

          slide.id = dto.id;
          slide.isNew = false;
          slide.organizationId = dto.organizationId;
        }
        catch (e) {
          return e.message;
        }
      }
      return null;
    },

    async saveUpdatedSlides({getters}) {
      if (getters.slides.length < 1) {
        return "Er moet minstens 1 item aangemaakt worden";
      }

      for (let slide of getters.slides.filter(s => s.id > 0)) {
        const updatedSlideDto = new UpdateWelcomeSlideDto(slide);

        try {
          await updateSlide(updatedSlideDto);
        }
        catch (e) {
          return e.message;
        }
      }
      return null;
    },

    async saveRemovedSlides({getters}) {
      const removedSlides = getters.removedSlides.filter(s => s.id > 0);
      for (let slide of removedSlides) {
        try {
          await removeSlide(slide.id);
        }
        catch (e) {
          return e.message;
        }
      }
      return null;
    }
  }
});
  
export default store;