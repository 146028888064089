class NewWelcomeSlideDto {
	constructor(data) {
		this.id = data.id
		this.title = data.title;
		this.description = data.description;
		this.sortOrder = data.sortOrder;
		this.buttonColorCode = data.buttonColor;
		this.buttonText = data.buttonText;
		this.buttonTextColorCode = data.buttonTextColor;
		this.textColorCode = data.textColor;
		this.visualIconImageData = data.iconBase64;
		this.visualBackgroundImageData = data.backgroundBase64;
	}
}

export default NewWelcomeSlideDto;