<template>
  <div 
    class="slide-preview"
  >
    <div
      class="slide-preview-container"
      :style="slideStyle"
    >
      <div
        class="slide-image-container"
      >
        <slide-preview-image
          :slide="slide"
        />
      </div>

      <div
        class="slide-content-container d-flex flex-column justify-content-center align-items-center"
      >
        <slide-preview-title
          :slide="slide"
        />

        <slide-preview-text
          :slide="slide"
        />

        <slide-preview-button
          :slide="slide"
        />
      </div>
    </div>
  </div>
</template>

<script>
import WelcomeSlideStore from '@/store/pages/WelcomeSlideStore/welcome-slide-store.js';

import SlidePreviewButton from './SlidePreviewButton.vue';
import SlidePreviewImage from './SlidePreviewImage.vue';
import SlidePreviewText from './SlidePreviewText.vue';
import SlidePreviewTitle from './SlidePreviewTitle.vue';

export default {
  components: {
    SlidePreviewButton,
    SlidePreviewImage,
    SlidePreviewText,
    SlidePreviewTitle,
  },

  props: {
    selected: { type: Boolean, default: false },
    slide: { type: Object, required: true },
  },

  computed: {
    slides() {
      return WelcomeSlideStore.state.slides;
    },

    hasBackgroundImage() {
      return (
        this.slide.backgroundBase64 != null ||
        this.slide.backgroundUrl !== ""
      );
    },

    slideBackgroundUrl() {
      return this.slide.backgroundBase64 ??
        this.slide.backgroundUrl;
    },

    slideStyle() {
      return {
        'background-image': this.hasBackgroundImage
          ? `url('${this.slideBackgroundUrl}')`
          : null
      };
    },
  }
}
</script>

<style lang="scss" scoped>
.slide-preview {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  overflow: hidden;
  border-radius: 35px;

  font-size: 18px;
}

.slide-preview-number {
  margin-block: 0;
  margin-bottom: 5px;
}

$preview-width: 200px;

.slide-preview-container {
  position: relative;
  overflow: hidden;
  padding: 10%;

  width: $preview-width;
  height: calc($preview-width * 2.032);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  background-size: cover;
  background-position: center;
}

.slide-preview::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('/assets/img/iphone-frame.png');
  background-size: contain;
  background-repeat: no-repeat;
}

.slide-image-container {
  width: 100%;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}
</style>