<template>
  <div
    class="slide-list-container"
  >
    <div class="slides-list">
      <slide-preview
        v-for="slide in slides"
        :key="slide.key"
        :selected="currentSlide?.key === slide.key"
        :slide="slide"
        @click="() => selectActiveSlide(slide.key)"
      />
      <add-slide-button />
    </div>
  </div>
</template>

<script>
import SlidePreview from './SlidePreview/SlidePreview.vue';
import AddSlideButton from './AddSlideButton.vue';

import WelcomeSlideStore from '@/store/pages/WelcomeSlideStore/welcome-slide-store.js';

export default {
  components: {
    AddSlideButton,
    SlidePreview,
  },

  computed: {
    slides() {
      return WelcomeSlideStore.state.slides;
    },
    currentSlide() {
      return WelcomeSlideStore.getters.currentSlide;
    }
  },

  methods: {
    selectActiveSlide(key) {
      WelcomeSlideStore.dispatch('selectSlide', key);
    }
  }
}
</script>

<style>
.slide-list-container {
  border: 1px dashed #82b9f5;
  border-radius: 20px;
  min-height: 440px;
  height: 400px;

  overflow-x: scroll;
}

.slides-list {
  display: flex;
  align-items: center;
  height: 100%;
}
</style>