<script>
import WelcomeSlideStore from '@/store/pages/WelcomeSlideStore/welcome-slide-store.js';

import ColorPickerButton from '@/components/UI/ColorPickerButton.vue';

export default {
  components: {
    ColorPickerButton,
  },

  computed: {
    textColor: {
      get() { return WelcomeSlideStore.getters.currentSlide?.textColor ?? ""; },
      set(value) {
        WelcomeSlideStore.dispatch('updateCurrentSlideTextColor', value);
      }
    },

    buttonColor: {
      get() { return WelcomeSlideStore.getters.currentSlide?.buttonColor ?? ""; },
      set(value) {
        WelcomeSlideStore.dispatch('updateCurrentSlideButtonColor', value);
      }
    },

    buttonTextColor: {
      get() { return WelcomeSlideStore.getters.currentSlide?.buttonTextColor ?? ""; },
      set(value) {
        WelcomeSlideStore.dispatch('updateCurrentSlideButtonTextColor', value);
      }
    },
  }
}
</script>

<template>
  <div>
    <h6>Kleuren</h6>
    <color-picker-button 
      v-model="textColor"
      label="Tekstkleur"
      class="mb-2"
    />

    <color-picker-button 
      v-model="buttonColor"
      label="Knopkleur"
      class="mb-2"
    />

    <color-picker-button 
      v-model="buttonTextColor"
      label="Knoptekstkleur"
      class="mb-2"
    />
  </div>
</template>