<script>

import { createYesNoSweetAlert } from '@/helpers/sweetalert';

const getImageSizeError = (width, height) => 
  `Afbeelding mag een maximum-resolutie hebben van ${width}x${height}.`;

  export default {
  props: {
    label: { type: String, required: true, },
    modelValue: { type: [String, null], required: true, },
    maxImageWidth: { type: Number, default: Number.MAX_SAFE_INTEGER, },
    maxImageHeight: { type: Number, default: Number.MAX_SAFE_INTEGER, },
  },

  emits: [
    'update:modelValue',
  ],

  data() {
    return {
      key: crypto.randomUUID(),
      error: null
    };
  },

  computed: {
    data: {
      get() { return this.modelValue; },
      set(value) { this.$emit('update:modelValue', value); }
    },

    hasImage() {
      return (
        this.modelValue != null &&  
        this.modelValue !== ""
      );
    },

    buttonIndicatorStyle() {
      return {
        backgroundColor: this.color,
      }
    }
  },

  methods: {
    handleIconFileUpload(event) {
      this.processLocalFile(event.target.files[0]);
    },

    processLocalFile(file) {
      this.error = null;

      let reader = new FileReader();
      let image = new Image();

      reader.onload = () => {
        image.src = reader.result;
      }

      image.onload = () => {
        if (
          image.width > this.maxImageWidth ||
          image.height > this.maxImageHeight
        ) {
          this.error = getImageSizeError(
            this.maxImageWidth,
            this.maxImageHeight
          );
          return;
        }
        
        this.data = image.src;
      }

      reader.readAsDataURL(file);
    },

    removeImage() {
      createYesNoSweetAlert(
        'Weet u zeker dat u de afbeelding wilt verwijderen?',
        () => this.data = null
      );
    }
  }
}
</script>

<template>
  <div>
    <label 
      :for="`image-upload-${key}`"
      class="d-flex justify-content-between align-items-center"
      :class="hasImage ? 'filled' : ''"
    >
      {{ label }}

      <img
        v-if="hasImage"
        :src="modelValue"
      />

      <span
        v-else
      >
        Geen<br/>afbeelding
      </span>
    </label>
    <p 
      v-if="error != null"
      class="ps-2 error"
    >
      {{ error }}
    </p>
    <input 
      :id="`image-upload-${key}`"
      class="d-none" 
      type="file" 
      accept="image/*"
      @change="handleIconFileUpload"
    />
    <span
      v-if="modelValue != null"
      class="material-icons-round remove-button"
      @click.prevent="removeImage"
    >
      delete
    </span>
  </div>
</template>

<style lang="scss" scoped>
div {
  position: relative;
}

label {
  margin: 0;
  border: 1px solid #d2d6da;
  border-radius: 0.375rem;
  padding: 10px;
  font-weight: 500;

  &:hover {
    font-weight: 700;
    background: #00000004;
  }

  &.filled {
    border-style: solid;
    border-color: #4caf50;
    box-shadow: inset 1px 0 #4caf50, inset -1px 0 #4caf50, inset 0 1px #4caf50, inset 0 -1px #4caf50;
  }
}

img {
  height: 60px;
}

span {
  text-align: center;

  &.remove-button {
    position: absolute;
    right: -0.5em;
    top: -0.5em;
    background: #e91e63;
    font-size: 1em;
    color: #ffffff;
    font-weight: 500;
    border: 2px solid transparent;
    border-radius: 50%;
    padding: 2px;
    margin-left: 5px;
    cursor: pointer;

    transition: all 50ms linear;

    &:hover {
      color: #e91e63;
      background-color: #ffffff;
      border-color: #e91e63;
    }
  }
}

p.error {
  font-size: 0.8em;
  color: #ff0000;
}
</style>