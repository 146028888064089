<script>
import WelcomeSlideStore from '@/store/pages/WelcomeSlideStore/welcome-slide-store.js';

import EditingSlidePreview from '@/components/WelcomeSlide/SlidePreview/EditingSlidePreview.vue';
export default {
  components: {
    EditingSlidePreview,
  },
  computed: {
    hasNoActiveSlide() {
      return WelcomeSlideStore.getters.hasNoActiveSlide;
    },
    slide() {
      return WelcomeSlideStore.getters.currentSlide;
    }
  }
}
</script>

<template>
  <div>
    <div
      class="d-flex flex-column justify-content-center align-items-center h-100"
    >
      <h6
        class="text-center"
      >
        Preview
  	    <span
          title="Formaat/Witruimte kan afwijken afhankelijk van apparaat."
        >🛈</span>
      </h6>
      <p v-if="hasNoActiveSlide">Geen item geselecteerd</p>
      <editing-slide-preview 
        v-else
        :selected="false"
        :slide="slide"
      />
    </div>
  </div>
</template>

