<script>
export default {
  props: {
    slide: { type: Object, required: true, },
  },

  computed: {
    hasText() {
      return this.slide.buttonText !== "";
    },
    buttonStyle() {
      return {
        backgroundColor: this.slide.buttonColor ?? "#000000", 
      }
    },
    buttonTextStyle() {
      return {
        color: this.slide.buttonTextColor ?? "#ffffff", 
      }
    }
  }
}
</script>

<template>
  <div
    class="slide-preview-button"
    :style="buttonStyle"
  >
    <p
      v-if="hasText"
      :style="buttonTextStyle"
    >
      {{ slide.buttonText }}
    </p>
    <p 
      v-else
      :style="buttonTextStyle"
    >
      Knoptekst
    </p>
  </div>
</template>

<style scoped>
.slide-preview-button {
  border-radius: .2em;
  display: inline-block;
  line-height: 1;
}

p {
  margin: 0;
  font-size: 0.6rem;
  display: inline-block;

  padding-inline: 2.0em;
  padding-top: 0.1em;
  padding-bottom: 0.3em;
  border-radius: .2em;
  font-size: .5em;
  font-weight: 500;
}
</style>