import {
	deleteRequest,
	getRequest, 
	postRequest, 
	putRequest 
} from '..';

const endpoint = '/onboardingslide';

export function getSlides() {
	return getRequest(endpoint);
}

export function saveNewSlide(slide) {
	return postRequest(endpoint, slide);
}

export function updateSlide(slide) {
	return putRequest(endpoint, slide);
}

export function removeSlide(id) {
	return deleteRequest(`${endpoint}/${id}`)
}