<template>
  <div class="add-slide-button-container">
    <div
      class="add-slide-button"
      @click="addEmptySlide"
    >
      <span class="material-icons-round">
        add
      </span>
    </div>
  </div>
</template>

<script>
import WelcomeSlideStore from '@/store/pages/WelcomeSlideStore/welcome-slide-store';

export default {
  methods: {
    addEmptySlide() {
      WelcomeSlideStore.dispatch('addEmptySlide');
    }
  }
}
</script>

<style>
.add-slide-button-container {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;

  padding-inline: 30px;

  margin-inline: auto;
}

.add-slide-button {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 50px;
  height: 50px;

  border: 1px solid #82b9f5;
  border-radius: 50%;

  user-select: none;
}

.add-slide-button:hover {
  background-color: #82b9f5;
  color: #ffffff;
}
</style>