class WelcomeSlideModel {
	constructor(
	  id,
	  title, 
	  description, 
	  buttonText, 
	  textColor, 
	  buttonColor, 
	  buttonTextColor, 
	  backgroundUrl, 
	  iconUrl,
	  sortOrder,
		organizationId,
		isNew = false,
	) {
		this.id = id;
		this.title = title;
		this.description = description;
		this.buttonText = buttonText;
		this.buttonColor = buttonColor;
		this.buttonTextColor = buttonTextColor;
		this.textColor = textColor;
		this.backgroundUrl = backgroundUrl,
		this.iconUrl = iconUrl;
		this.backgroundBase64 = null;
		this.iconBase64 = null;
		this.sortOrder = sortOrder,
		this.organizationId = organizationId;
		this.key = crypto.randomUUID();
		this.isNew = isNew;
	}
	
	static empty(sortOrder) {
	  return new WelcomeSlideModel(
		0,
		'',
		'',
		'',
		'#000000',
		'#000000',
		'#ffffff',
		'',
		'',
		sortOrder,
		null,
		true
	  );
	}

	static fromDto(welcomeSlideDto) {
		return new WelcomeSlideModel(
			welcomeSlideDto.id,
			welcomeSlideDto.title, 
			welcomeSlideDto.description, 
			welcomeSlideDto.buttonText , 
			welcomeSlideDto.textColorCode ?? "#000000", 
			welcomeSlideDto.buttonColorCode ?? "#000000", 
			welcomeSlideDto.buttonTextColorCode ?? "#FFFFFF", 
			welcomeSlideDto.visualBackgroundImage, 
			welcomeSlideDto.visualIconImage,
			welcomeSlideDto.sortOrder,
			welcomeSlideDto.organizationId,
			false,
		);
	}
}

export default WelcomeSlideModel;